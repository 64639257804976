'use strict'
const _ = require('lodash')

const dsServerUrl = siteHeaderUrl => `https://apps.wix.com/document-services-server/query/apps/${siteHeaderUrl}`
const fetchDS = (siteHeaderUrl, resource) => fetch(`${dsServerUrl(siteHeaderUrl)}${resource}`, {
    credentials: 'include'
}).then(res => res.json())

const getWidgetId = ({rootCompId}) => rootCompId[0] === '#' ? rootCompId.substr(1) : rootCompId

const getWidgetStructure = (siteHeaderUrl, widgetId) => fetchDS(siteHeaderUrl, `/widget/${widgetId}`)

const getWidgetStructureByAppData = async (appData, widgetPageId, variationId) => {
    const {appDefinitionId, widgets} = appData
    const siteHeaderUrl = _.get(appData, 'appFields.platform.baseUrls.siteHeaderUrl') || _.get(appData, 'appFields.platform.studio.siteHeaderUrl')
    const devCenterWidgetId = _.findKey(widgets, widgetData => _.get(widgetData, 'componentFields.appStudioFields.id') === widgetPageId)
    const createVariationIdParam = () => `&variationId=${variationId}`

    const url = `/app_widget/${widgetPageId}?appDefinitionId=${appDefinitionId}&devCenterWidgetId=${devCenterWidgetId}${variationId ? createVariationIdParam() : ''}`
    const {APP_WIDGET} =
        await fetchDS(siteHeaderUrl, url)
    
    return APP_WIDGET
}

/**
 * @description Fetches the appDescriptor from DS Server
 * @returns An object with widgets property, keys are widgetId, value is widget descriptor
 */
const getAppDescriptor = siteHeaderUrl =>
    fetchDS(siteHeaderUrl, '/app')
        .then(app => {
            const {APP_DESCRIPTOR} = app
            const appDescriptor = _.isArray(APP_DESCRIPTOR) ? {widgets: APP_DESCRIPTOR} : APP_DESCRIPTOR
            return _.defaults({
                widgets: _.keyBy(appDescriptor.widgets, getWidgetId),
                customDefinitions: _.assign({}, ..._.get(appDescriptor, 'customDefinitions', []))
            }, appDescriptor)
        })

module.exports = {
    getWidgetStructure,
    getAppDescriptor,
    getWidgetStructureByAppData
}
